export const HOME_PAGE = '/'
export const EVENTS_PAGE = '/events'
export const GODMOTHER_PAGE = '/mentorship'
export const MEMBERS_PAGE = '/members'
export const BLOG_LIST = '/blog'
export const TOUR_PAGE = '/tour'
export const BLOG_ID = '/blog/:id'
export const PARTENARIAT_PAGE = '/partenariat'

export const JUNIOR = 'junior'
export const DEVENIR_PARTENAIRE = 'devenirPartenaire'
