import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { HOME_PAGE } from 'shared/routes'

const Page404 = (): null => {
  useEffect(() => {
    navigate(HOME_PAGE)
  }, [])
  return null
}

export default Page404
